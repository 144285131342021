export default {
  title: "Beneficiaries",
  count: "Active beneficiaries",
  budget_title: "Bugets",
  telework_budget_title: "Telework bugets",
  ppp3_budget_title: "PPP3 bugets",
  ppp3_title: "PPP3",
  all_history_title: "Auto Top-Ups History",
  tme_auto_top_up_history: "Meal voucher",
  ppp3_auto_top_up_history: "Private pension",
  insurance_auto_top_up_history:"Medical insurance",
  history_title: "History",
  time_sheet: "Time sheet",
  updated_at: "Updated at",
  timesheets: "Timesheets",
  auto_top_ups: "Auto top ups",
  requests: "Request",
  days_worked: "Days worked",
  last_top_up_date: "Last topup date",
  fields: {
    filter_title: "Filter beneficiaries",
    search: "Search",
    beneficiary: "Beneficiary",
    client_company: "Client company",
    invitation_email: "Invitation email",
    telephone: "Telephone",
    cnp: "CNP",
    employee_id: "Employee ID",
    group: "Group",
    status: "Status",
    client: "Client",
    department: "Department",
    job_title: "Job title",
    cost_center: "Cost center",
    working_point: "Subsidiary",
    up_ro_address_id: 'Card delivery address code',
    address: 'Address',
    previous_budget: 'Previous budget',
    ppp3_budget: 'PPP3 budget',
    ppp3_expiration_date: 'Expiration PPP3 date',
    new_budget: 'Actual budget',
    difference: 'Difference',
    updated_budget_type: 'Update reason',
    contribution_type: 'Contribution type',
    contribution_value: 'Contribution Value',
    personal_contribution_value: 'Personal Contribution Value',
    company_contribution_value: 'Company Contribution Value',
    contract_number: 'Policy number',
    benefit: 'Benefit',
    action: 'Action',
    number_of_tickets:'Number of tickets',
    ticket_value: 'Ticket value',
  },
  tooltip: {
    default_value: 'Default global value',
  },
  updated_budget_types: {
    import: 'Import',
    consume: 'Consume',
    cron_job: 'System',
    manual: 'Individual edit',
  },
  request: {
    number: "Request nr",
    placed_on: "Placed on",
    amount: "Amount",
    status: "Request status",
    order_number: "Order number",
    order_placed_on: "Order placed on",
    order_status: "Oder status",
    reject_order: "Cancel request"
  },
  status_change_successfully: "Status change successfully",
  invitation_sent_successfully: "Email invitation sent successfully",
  change_status_to_invited_success: "Beneficiary status was successfully changed.",
  reActivateBeneficiary_success: "The beneficiary has been successfully reactivated.",
  invitation_sent_error: "Could not send email invitation.Please try again later",
  change_status_to_invited: "Could not change status. Please try again later",
  change_status_error: "Could not change status. Please try again later",
  entries: "Entries",
  modal: {
    this_will_remove_the_beneficiary_from: [
      "This will remove the beneficiary from",
      "This will remove {count} beneficiaries from"
    ],
    any_available_budget: [
      "Any available budget in the beneficiary's account will be removed",
      "Any available budget in the beneficiaries account will be removed"
    ],
    this_action_cannot_be_reverted: "this action cannot be reverted at a later time",
    this_will_suspend_the_recipient_from: [
      "This will suspend the beneficiary from <span class=\"text-bold\"> {companyName} </span> effective immediately.",
      "This will suspend {count} beneficiaries from <span class=\"text-bold\"> {companyName} </span> effective immediately."
    ],
    you_will_still_be_able: "You will still be able to access and edit their information, but the corresponding user licence will not be billed.",
    during_suspension_the_beneficiary: [
      "During suspension, the beneficiary will not have access to their company account, including budgets or top-ups. This will not affect ongoing orders",
      "During suspension, the beneficiaries will not have access to their company account, including budgets or top-ups. This will not affect ongoing orders"
    ],
    this_action_can_be_reverted_at_any_time: "This action can be reverted at any time",
    this_will_reactivate_the_beneficiary: [
      "This will reactivate the beneficiary from <span class=\"text-bold\">{companyName}</span>effective immediately.",
      "This will reactivate the selected beneficiaries from <span class=\"text-bold\">{companyName}</span>effective immediately."
    ],
    this_will_change_status_to_invited: 'This action will delete all authentication data associated with the beneficiary. Consequently, the beneficiary will no longer have access using the current credentials and will be invited to provide the necessary information for authentication',
    this_will_reactivate_user: 'Are you sure you want to reactivate the user?',
    an_email_invitation_will: "An email invitation will be resent to the current email set in the Email invitation field for <span class=\"text-bold\">{companyName}</span>.",
    if_you_have_changed_the_email: "If you have changed the email make sure you have saved it.",
    are_you_sure_you_want_to_save_the_changes: "Are you sure you want to save the changes?",
    the_information_has_been_changed: "The information has been changed, are you sure you want to leave?",
    could_not_change: 'Chould not change beneficiaries statuses!',
    statuses_changed: [
      '{count} status has changed',
      '{count} statuses have changed',
    ],
  },
  mass_actions: {
    selected_count: 'Number of selected items ',
    not_found_count: 'Number of not elements not found',
    btn_bulk_actions: 'Bulk Actions',
    btn_download_example: 'Download Import Example',
    btn_upload_import: 'Upload Import',
    btn_export: 'Export beneficiaries',
    messages: {
      malformed_file: "The import file does not respect the template file or fields not well formatted.",
      import_success: 'The import was successful',
    }
  },
  consumed_days: 'Consumed',
  btn: {
    save_budget: 'Save budget',
    save_telework_budget: 'Save telework budget',
    save_ppp3_budget: 'Save PPP3 budget',
    save_time_sheet: 'Save time sheet',
  },
  titles: {
    budget: 'Flexible budget',
    telework_budget: 'Telework budget',
    ppp3_budget: 'PPP3 budget',
  },
  company: {
    taxation_subtitle: 'Taxation',
    gross_budget_taxation_salary: 'Salary',
    gross_budget_taxation_budget: 'Budget',
    gross_budget_taxation_subtitle: 'Gross',
    net_budget_taxation_subtitle: 'Net',
  }
}
