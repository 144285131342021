export default {
  top_up_now: 'Top-UP NOW',
  top_up_find_how: 'Find out how',
  order_now: 'Order now',
  benefit_box: {
    up_dejun: 'Meal card: the modern solution for more motivated employees',
    up_vacation: 'Vacation card: the modern solution for a stable and efficient team',
    up_culture: 'Cultural card: the modern solution for happier employees',
    up_gift: 'Gift card: the modern solution for more involved employees',
  },
  benefits: {
    all_benefits : 'All benefits',
    list_title: 'Flexible benefits',
    title_list_topup_benefits: 'Enabled auto topups ',
    go_back: 'Back to benefits',
    other_benefits: 'Other flexible benefits ',
    tab_title: 'Top-up your {card_title} card',
    what_is: 'What is {benefit}?',
    success_toggle_auto_topup: 'Toggle auto-topup successfully!',
    success_direct_order: 'Order directly successfully!',
    top_up_info: 'Top-up information',
    top_up_value: 'Top-up value',
    top_up_count: 'No of top-up tickets',
    no_benefits_available: 'Ops! There are no available benefits at this moment.',
    salary_deductibility: 'Taxes from salary',
    salary_taxes: 'Taxes from budget',
    yearly_legislated_limit: 'The yearly legislated limit is',
    budget_under_min_limit: 'Available budget under the minimul {min_val} lei legal value per order.',
    deductibility_remaining: 'Deductibility limit',
    no_occasions: 'No occasions',
    monthly_legislated: 'The monthly legislated limit is',
    attribution_month: 'Monthly attribution',
    attribution_occasion: 'Specific company occasion',
    already_used: 'Used allocated budget for {benefit}!<br>Check placed orders to cancel.',
    already_used_month: 'Already allocated budget for {benefit} this month!<br>Check placed orders to cancel.',
    client_no_access: 'For the moment your employer does not offer access to this benefit.',
    no_time_sheet: 'No time sheet allocated for current month.',
    days_worked: 'days worked in',
    consumed: 'days consumed',
    last_day_to_place: 'Last day to place topup',
    ticket_value: 'Daily ticket base is',
    no_events: ' No events available',
    not_available: 'Benefit not available',
    max_stim: 'Maximum company {name} topup value ',
    auto_toggle: 'Turn on auto-topups',
    fields_to_complete: 'Please fill out all marked fields',
    personal_use_yes:  "I am purchasing this subscription for myself",
    personal_use_no: "I am purchasing this subscription for someone else",
    budget: {
      available: 'Available budget until',
      allocated: 'Used budget in',
      taxes: 'Taxes due for {date} <br/> that may be deducted from your salary',
    },
    insufficient_budget: 'Insufficient budget to order',
    cam_tax: 'CAM tax value',
    modal: {
      auto_topup: {
        title: 'Auto top-ups',
        text_1: 'You are about to <b>{action}</b> auto monthly top-ups of your {benefit} card!',
        text_2: 'When your employee allocates your budget and records your working days for the month, your card will be automatically topped-up with the desired amount, for each worked day.',
        text_2_count: 'When your employee allocates your budget and records your working days for the month, your card will be automatically topped-up with the chosen no. of tickets multiplied by {value} Lei.',
        text_3: 'If you do not yet have an Up Dejun card, this action will place a request for such a card to your employer.',
        label: 'Daily ticket value for auto top-up',
        label_count: 'No. of tickets for auto top-up',
        button_enable: 'Enable auto top-up',
        button_disable: 'Disable auto top-up',
        button_enable_pp3: 'If the supply value does not correspond to the one agreed in the policy, payment will be refused by the supplier',
        invalid_value: 'Ticket value must be between {min} and {max}!',
        last_day_hour_exceeded: 'Last day and hour to place request exceeded! <br>({date_hour})',
        confirm_update: 'Update auto tup-up',
      },
    },
    tooltips: {
      auto_topup: 'Auto top-ups for {benefit} is here! <br/><br/> If turned on, every month an order will be placed, if the budget holds the necessary amount and your timesheet has been provided by your employer.',
      top_up_info_last_anual: 'Latest anual day for {benefit} top-up orders is {date}.',
      top_up_deduction: 'Depending on the industry you are working in and your job type, certain taxes and contributions may be excepted',
      top_up_multiple_year: 'You can top-up multiple times per year.',
      top_up_one_event: 'You can top-up only when your benefit is active.',
      top_up_one_occasion: 'You can top-up once per occasion.',
      top_up_unlimited_occasion: 'You can top-up multiple times per occasion.',
      top_up_unlimited: 'You can use this benefit to top-up as much as you want.',
      top_up_taxation: 'Please consider that the amount over {threshold} lei  may cause deduction of income tax ({tax}%), CAS({cas}%), CASS({cass}%) from your salary.',
      top_up_taxation_taxes: 'Please consider that the amount may cause deduction of income tax ({tax}%), CAS({cas}%), CASS({cass}%) from your salary.',
      select_event: 'You can select an event only if it\'s going to occur in the next 30 days or has already occurred in the past 30 days.',
      thresholds: {
        gym_membership: 'Please consider that the amount over {value} lei will cause deduction of income tax'
      },
      up_dejun: {
        top_up_info: 'You can top-up your {benefit} card depending on the number of days worked in a month and your available budget.<br/><br/> The latest date for placing orders for this month is the {date}.',
        every_month: 'of each month'
      },
      up_culture: {
        top_up_one_mounth : 'You can top-up once a month'
      },
      up_gift: {
        legislated: 'Easter and Christmas:<br>Depending on your available budget, the maximum amount you can use for top-up is {max} lei + {max} lei for each of your underage children.<br/><br/>1 June: <br> Depending on your available budget, the maximum amount you can use for top-up is {max} lei for each minor child.<br/><br/>8 March: <br> Depending on your available budget, the maximum amount you can use for top-up is {max} lei.',
        specific: 'Depending on your available budget, the maximum amount you can use for top-up is {max} lei'
      },
      sign_contract: 'In order to acquire this subscription, please apply on our partner website, sign the contract, get back, fill in the required fields and send an order request to your employer.',
      cam_tax: 'Labor Insurance Contribution',
      total_pay_value: 'The total amount that will be deducted from your available budget. Contains all taxes associated with this benefit.',
      employee: "Employee",
      personal: "Personal"
    },
    fields: {
      top_up_value_card: 'Card top up value Lei',
      value: 'Value',
      subscription_value: 'Value Lei',
      subscription_period: 'Periods',
      subscription_chose_period: 'Chose period',
      card_series: 'Card series',
      manual_ticket_value: 'Ticket value (Integer number between {min} and {max})',
      manual_value: 'Value (Integer number between {min} and {max})',
      contract_number: 'Contract number',
      contribution_type: 'Contribuion type',
      top_up_recurring_value: 'Monthly topup value',
      top_up_employer_recurring_value: 'Monthly employer topup value',
      top_up_personal_recurring_value: 'Monthly personal topup value',
      min_top_up_recurring_value: 'Minimum value {min} Lei',
      error_input_val: 'Please set only numeric value'
    },
    btn: {
      request_card: 'Request card',
      previous_card: 'Previous card series',
      add_series_card: 'Add card series',
      sign_contract: 'Apply now',
      set_manual_ticket: 'Manually set ticket value',
      set_manual: 'Manually set value',
      activate_auto_top_up: 'Turns on automatic topup',
      disable_auto_top_up: 'Turns off automatic topup',
      update_auto_top_up: 'Update automatic topup',
      where_to_use_vouchers: 'Where can I use Up cards',
      add_another_personal_type: 'Activeaza on noua alimentare, Tip Contributie: Personal'

    },
    action_message: {
      order_now: 'Product ordered successfully!',
      are_you_sure: 'Are you sure you want to order now?',
      are_you_sure_top_up_live: 'Placed orders cannot be cancelled!',
    },
    sign_contract_info: 'Send your request to our partner',
    total_value: 'TOTAL VALUE',
    non_legislated_already_month_use: 'Warning! You already have auto topup active for benefits of the fallowing type {type} on {benefit}.',
    is_set_mixt_contribution_type: 'Warning! You already have auto topup activated for the mixed contribution type.',
    is_set_personal_employeer_contribution_type: 'Warning! You already have auto topup activated for the personal/employer contribution type.',
    go_to_mixt_contribution_type: 'Go to mixt contribution type',
    non_legislated_recurring_not_taxed: 'We inform you that the automatic withdrawal of the set amount will be done at a fixed time (example: the activation made at 10:15 will be processed at 11:00)<br> Monthly topup value so that additional taxes are not applied: {value} Lei for the next {remainingYearMonths} months.',
    non_legislated_recurring_not_taxed_extra: '(incliding current month from the moment you activate)',
    non_legislated_recurring_ordered: 'Topup for current month has been made.<br/> Any alterations to the value or contract number will be taken into account for the next topup.',
    last_day_to_order_for_next_month: 'For orders placed up to and including {day} of the current month, the subscription will be active starting on the 1st of the following month. <br> For orders placed after {day} of the current month, the subscription will be active from {start_date}.',
    cash_out_last_order_day_exceeded: 'The last day of the month, the {day}, for cash out has been exceeded.',
    auto_topup_cash_out: 'Activate a new feed, Contribution Type: Personal'
  },
  auto_topup_benefits: {
    title: 'Active auto top-up',
    description: 'Benefits with auto top-up enabled'
  },
  requests: {
    title: 'Requests for top-ups and cards',
    title_voucher_history: 'Voucher history',
    sub_title: 'requests',
    about: '',
    contact_us: '',
    filters: {},
    types: {
      top_up: 'Top-up request',
      top_up_live: 'Top-up request live',
      card_request: 'Card request',
      voucher: 'Gift vouchers',
      subscription: 'Subscriptions',
    },
    modal: {
      confirm_message: 'This will cancel request {request_id}. <br/> Please confirm action.',
      cancel_auto_topup: 'Do you also want to disable auto topup?',
      voucher_history: {
        title: 'Details',
        p1: 'Your voucher code is:',
      },
    },
    table: {
      title: 'All top-up and card requests',
      request_number: 'Request nr',
      placed_date: 'Placed on',
      type: 'Type',
      benefit: 'Benefit',
      amount: 'Benefit value',
      status: 'Status',
      taxes: 'Salary taxes',
      gross_amount: 'Total consumed budget',
      cam_value: 'CAM tax',
      budget_type: 'Budget type',
      partner: 'partner',
    },
    status: {
      new: "New",
      canceled: "Canceled",
      rejected: "Rejected",
      ordered: "Ordered",
      await_top_up: 'Processing',
      await_callback: 'Pending validation',
      validation_failed: 'Validation failed',
      bou_error: 'BOU Error',
    },
    budget_types: {
      telework: 'Telework',
      general: 'Flexible',
    }
  },
  dashboard: {
    available_budget: 'Available budget',
    title_top_up: 'Top-up your cards with flexible benefits',
    title_list_topup_benefits: 'Enabled auto top-up ',
    latest_top_up: 'Last 10 top-up requests',
    box: {
      budget_expiration_date: 'This budget will expire on the {day} of {month} {year}',
      budget_expiration_date_telework: 'Telework budget will expire on the <br> {day} of {month} {year}',
      budget_expiration_date_ppp3: 'PPP3 budget will expire on the <br> {day} of {month} {year}',
      total_budget_year: 'Total budget received in {year}',
      allocated_budget: 'Used budget in {month} {year}',
      last_credit: 'Last credited on',
      taxes_due: 'Taxes due {month} {year} <br> that may be deducted from your salary',
      no_allocation: 'No budget allocation',
    },
    tooltips: {
      telework_expire: 'When budget is about to expire, it will be transferred to your flexible budget'
    }
  },
  fixed_benefits: {
    title: 'Fixed benefits',
    no_benefits: 'Ops! there are no available benefits at this moment.',
    description: 'The standard package of benefits offered by your employer',
  }
}
