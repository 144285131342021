export default {
  top_up_now: 'Alimenteaza acum',
  top_up_find_how: 'Afla cum',
  order_now: 'Comanda acum',
  benefit_box: {
    up_dejun: 'Cardul de masa: solutia moderna pentru angajati mai motivati',
    up_vacation: 'Cardul de vacanta: solutia moderna pentru un colectiv stabil si performant',
    up_culture: 'Cardul cultural: solutia moderna pentru angajati mai fericiti',
    up_gift: 'Cardul cadou: solutia moderna pentru angajati mai implicati',
  },
  benefits: {
    all_benefits : 'Toate beneficiile',
    list_title: 'Beneficii flexibile',
    auto_topups: 'Auto alimentari activate',
    go_back: 'Inapoi la beneficii',
    other_benefits: 'Alte beneficii flexibile ',
    tab_title: 'Alimenteaza cardul {card_title} ',
    what_is: 'Ce este {benefit}?',
    success_toggle_auto_topup: 'Modificare alimentare automata salvata cu succes!',
    success_direct_order: 'Comanda directa cu succes!',
    top_up_info: 'Informatii alimentare',
    top_up_value: 'Valoare alimentare',
    top_up_count: 'Nr. de tichete alimentare',
    no_benefits_available: 'Ups! Momentan nu ai acces la niciun beneficiu.',
    salary_deductibility: 'Taxe retinute din salariu',
    salary_taxes: 'Retineri taxe din buget',
    yearly_legislated_limit: 'Limita legiferata anual este',
    budget_under_min_limit: 'Bugetul disponibil este sub limita minima legala a comenzii de {min_val} lei.',
    deductibility_remaining: 'Limita deductibila',
    no_occasions: 'Nu exista ocazii',
    monthly_legislated: 'Limita lunara legiferata este',
    attribution_month: 'Alimentare lunara',
    attribution_occasion: 'Ocazii specifice companie',
    already_used: 'Deja ai alocat buget pentru {benefit}!<br>Te rugam sa verifici comenzile plasate pentru anulare.',
    already_used_month: 'Deja ai alocat buget pentru {benefit} pe luna aceasta!<br>Te rugam sa verifici comenzile plasate pentru anulare.',
    client_no_access: 'Pentru moment angajatorul tau nu ofera acces la acest beneficiu.',
    no_time_sheet: 'Nu ai pontaj incarcat pentru luna curenta.',
    days_worked: 'zile lucrate in ',
    consumed: 'zile consumate',
    last_day_to_place: 'Ultima zi de solicitare',
    ticket_value: 'Valoarea tichetului',
    no_events: 'Nu exista evenimente disponibile',
    not_available: 'Beneficiu indisponibil',
    max_stim: 'Valoare maxima de alimentare stabilita de companie pentru {name}',
    auto_toggle: 'Porneste alimentarea automata',
    personal_use_yes: 'Achizitionez acest abonament pentru mine',
    personal_use_no: 'Achizitionez acest abonament pentru altcineva',
    fields_to_complete: 'Te rugam sa completezi toate campurile marcate',
    budget: {
      available: 'Buget valabil pana la data de',
      allocated: 'Buget consumat in',
      taxes: 'Impozite datorate pentru {date} <br/>care pot fi deduse din salariul tau',
    },
    insufficient_budget: 'Nu ai suficient buget pentru a comanda',
    cam_tax: 'Taxa valoare CAM',
    modal: {
      auto_topup: {
        title: 'Alimentare automata',
        text_1: 'Esti pe cale sa <b>{action}</b> incarcarile automate lunare ale cardului tau {benefit}!',
        text_2: 'Cand angajatorul iti aloca bugetul si inregistreaza pontajul, cardul tau va fi alimentat automat cu suma dorita, pentru fiecare zi lucrata.',
        text_2_count: 'Cand angajatorul iti aloca bugetul si inregistreaza pontajul, cardul tau va fi alimentat automat cu nr. de zile alese inmultit cu {value} Lei.',
        text_3: 'In cazul in care nu ai inca un card Up Dejun, aceasta actiune va conduce la trimiterea unei solicitari pentru un card de acest fel angajatorului tau.',
        label: 'Valoare zilnica a tichetului de masa pentru alimentare automata',
        label_count: 'Nr. de tichete de masa pentru alimentare automata',
        button_enable: 'Activeaza incarcare automata',
        button_enable_pp3: 'Daca valoarea de alimentare nu corespunde cu cea agreata in polita, plata va fi refuzata de catre furnizor',
        button_disable: 'Dezactiveaza incarcare automata',
        invalid_value: 'Valoarea tichetului trebuie sa fie intre {min} si {max}!',
        last_day_hour_exceeded: 'Ultima zi si ora pentru plasare solicitare depasita! <br>({date_hour})',
        confirm_update: 'Actualizati datele auto alimentare',
      },
    },
    tooltips: {
      auto_topup: 'Incarcarile automate pentru {benefit} sunt aici! <br/><br/> Daca este activat, in fiecare luna va fi plasata o comanda, daca bugetul detine suma necesara si pontajul a fost incarcat de angajator.',
      top_up_info_last_anual: 'Ultima zi din an pentru solicitarea incarcarii {benefit} este {date}.',
      top_up_deduction: 'In functie de industria in care activezi si pozitia ta, anumite contributii pot fi excluse.',
      top_up_multiple_year: 'Poti alimenta de mai multe ori pe an.',
      top_up_one_event: 'Poti alimenta doar in perioada in care acest beneficiu este activ.',
      top_up_one_occasion: 'Poti alimenta o singura data pe ocazie.',
      top_up_unlimited_occasion: 'Poti alimenta de mai multe ori pe ocazie.',
      top_up_unlimited: 'Poti solicita alimentarea de cate ori doresti.',
      top_up_taxation: 'Te rugam sa iei la cunostinta ca sumele peste {threshold} lei pot fi impozitate cu impozit pe venit({tax}%), CAS({cas}%), CASS({cass}%) din salariu.',
      top_up_taxation_taxes: 'Te rugam sa iei la cunostinta ca suma poate fi impozitata cu impozit pe venit({tax}%), CAS({cas}%), CASS({cass}%) din salariu.',
      select_event: 'Poti selecta un eveniment numai daca va avea loc in urmatoarele 30 de zile sau a avut loc deja in ultimele 30 de zile.',
      thresholds: {
        gym_membership: 'Te rugam sa iei la cunostinta ca sumele peste {value} lei vor fi impozitate'
      },
      up_dejun: {
        top_up_info: 'Iti poti alimenta cardul {benefit} in functie de numarul de zile lucrate in luna atribuirii si bugetul disponibil.<br/><br/>Ultima zi de solicitare de alimentare pentru {benefit} este ziua de {date}.',
        every_month: 'a fiecarei luni'
      },
      up_culture: {
        top_up_one_mounth : 'Poti alimenta o singura data pe luna'
      },
      up_gift: {
        legislated: 'Pastele si Craciun:<br> In functie de bugetul disponibil, suma maxima de alimentare este {max} lei + {max} lei pentru fiecare copil minor.<br/><br/>1 Iunie: <br> In functie de bugetul disponibil, suma maxima de alimentare este {max} lei pentru fiecare copil minor.<br/><br/>8 Martie: <br> In functie de bugetul disponibil, suma maxima de alimentare este {max} lei.',
        specific: 'In functie de bugetul disponibil, valoarea maxima pentru alimentare este {max} lei'
      },
      sign_contract: 'Pentru a intra in posesia acestui abonament, te rugam sa aplici pe site-ul partenerului, sa semnezi contractul cu acesta, sa revii si sa completezi informatiile din campurile necesare, iar mai apoi sa trimiti comanda catre angajator.',
      cam_tax: 'Contributie Asiguratorie pentru Munca',
      total_pay_value: 'Valoarea totala care urmeaza sa iti fie scazuta din bugetul disponibil. Contine toate taxele asociate acestui beneficiu.',
      employee: "Companie",
      personal: "Personal"
    },
    fields: {
      top_up_value_card: 'Valoare alimentare card Lei',
      value: 'Valoare',
      subscription_value: 'Valoare Lei',
      subscription_period: 'Perioade',
      subscription_chose_period: 'Alege perioada',
      card_series: 'Serie card',
      manual_ticket_value: 'Valoarea tichetului (Numar intreg intre {min} si {max})',
      manual_value: 'Valoare (Numar intreg intre {min} si {max})',
      contract_number: 'Numar Polita',
      contribution_type: 'Tip Contributie',
      top_up_recurring_value: 'Valoare alimentare lunara',
      top_up_employer_recurring_value: 'Valoare alimenatata de companie lunar',
      top_up_personal_recurring_value: 'Valoare alimentata personal lunar',
      min_top_up_recurring_value: 'Valoare minima {min} Lei',
      error_input_val: 'Te rog sa setezi doar o valoare numerica'
    },
    btn: {
      request_card: 'Solicita card',
      previous_card: 'Serie card precedenta',
      add_series_card: 'Adauga serie card',
      sign_contract: 'Aplica acum',
      set_manual_ticket: 'Seteaza valoare tichet manual',
      set_manual: 'Seteaza valoare manual',
      activate_auto_top_up: 'Activeaza alimentarea automata',
      disable_auto_top_up: 'Dezactiveaza alimentarea automata',
      update_auto_top_up: 'Actualizeaza alimentarea automata',
      where_to_use_vouchers: 'Unde pot utiliza cardurile Up',
      add_another_personal_type: 'Activeaza un nou flux, Tip de contribu?ie: Personal'
    },
    action_message: {
      order_now: 'Produsul a fost comandat cu succes!',
      are_you_sure: 'Sunteti sigur ca doriti sa comandati acum?',
      are_you_sure_top_up_live: 'Comenzile plasate nu pot fi anulate!',
    },
    sign_contract_info: 'Trimite o cerere catre partener',
    total_value: 'VALOARE TOTALA',
    non_legislated_already_month_use: 'Atentie! Aveti deja activata auto alimentarea pentru beneficiul de tipul {type} in cadrul {benefit}.',
    is_set_mixt_contribution_type: 'Atentie! Aveti deja activata auto alimentarea pentru beneficiul, tip contributie mixta.',
    is_set_personal_employeer_contribution_type: 'Atentie! Aveti deja activata auto alimentarea pentru beneficiul tip contributie personala/angajator.',
    go_to_mixt_contribution_type: 'Acceseaza contributie tip mixt',
    non_legislated_recurring_not_taxed: 'Va informam ca retragerea automata a sumei setate se va face la ora fixa.(exemplu: activarea facuta la ora 10:15 se va procesa la ora 11:00)<br> Valoarea alimentarii lunare, pentru a nu plati retineri suplimentare din salariu: {value} Lei pentru urmatoarele {remainingYearMonths} luni.',
    non_legislated_recurring_not_taxed_extra: '(incluzand luna curenta din momentul activarii)',
    non_legislated_recurring_ordered: 'Alimentarea pentru luna curenta a fost realizata. <br/> Orice modificare a valorii sau numarului de contract vor fi folosite in cadrul urmatoarei alimentari.',
    last_day_to_order_for_next_month: 'Pentru comenzile plasate pana in data de {day} (inclusiv) a lunii in curs, abonamentul va fi activ incepand cu data de 1 a lunii urmatoare. <br> Pentru comenzile plasate dupa data de {day} a lunii in curs, abonamentul va fi activ din data de {start_date}.',
    cash_out_last_order_day_exceeded: 'Ultima zi din luna, {day}, pentru cash out a fost depasita',
    auto_topup_cash_out: 'Alimentarea automata va rula o singura data pe luna in curs, daca nu exista o alta solicitare plasata.'
  },
  auto_topup_benefits: {
    title: 'Auto alimentari activate',
    description: 'Beneficii cu optiunea de auto alimentare activata'
  },
  requests: {
    title: 'Solicitari pentru emitere si alimentare carduri',
    title_voucher_history: 'Istoric vouchere',
    sub_title: "solicitari",
    about: '',
    contact_us: '',
    filters: {},
    types: {
      top_up: 'Alimentare card',
      top_up_live: 'Alimentare card live',
      card_request: 'Emitere card',
      voucher: 'Vouchere cadou',
      subscription: 'Abonamente',
    },
    modal: {
      confirm_message: 'Solicitarea cu numarul {request_id} o sa fie anulata. <br/> Te rugam sa confirmi.',
      cancel_auto_topup: 'Doriti sa dezactivati si alimentarea automata?',
      voucher_history: {
        title: 'Detalii',
        p1: 'Codul voucherului este:',
      },
    },
    table: {
      title: 'Toate solicitarile de emitere si alimentare carduri',
      request_number: 'Nr solicitare',
      placed_date: 'Plasat la',
      type: 'Tip',
      benefit: 'Beneficiu',
      amount: 'Valoare beneficiu',
      status: 'Status',
      taxes: 'Taxe salariu',
      gross_amount: 'Total buget consumat',
      cam_value: 'Taxa CAM',
      budget_type: 'Tip buget',
      partner: 'Partener',
    },
    status: {
      new: "Nou",
      canceled: "Anulat",
      rejected: "Respins",
      ordered: "Comandat",
      await_top_up: 'In procesare',
      await_callback: 'Asteapta validare',
      validation_failed: 'Invalid',
      bou_error: 'Eroare BOU',
    },
    budget_types: {
      telework: 'Telemunca',
      ppp3: 'PPP3',
      general: 'Flexibil',
    }
  },
  dashboard: {
    available_budget: 'Buget disponibil',
    title_top_up: 'Beneficiile tale flexibile',
    title_list_topup_benefits: 'Auto alimentari activate',
    latest_top_up: 'Ultimele 10 solicitari',
    box: {
      budget_expiration_date: 'Acest buget va expira pe data de <br/> {day} {month} {year}',
      budget_expiration_date_telework: 'Buget telemunca expira pe data de <br/> {day} {month} {year}',
      budget_expiration_date_ppp3: 'Buget PPP3 expira pe data de <br/> {day} {month} {year}',
      total_budget_year: 'Buget total primit in anul {year}',
      allocated_budget: 'Buget folosit in luna {month} {year}',
      last_credit: 'Ultima incarcare de buget din ',
      taxes_due: 'Taxe pentru {month} {year} <br> care ar putea fi deduse din salariu',
      no_allocation: 'Nici un buget alocat',
    },
    tooltips: {
      telework_expire: 'In momentul expirarii, bugetul va fii transferat in bugetul tau flexibil'
    }
  },
  fixed_benefits: {
    title: 'Beneficii fixe',
    no_benefits: 'Ups! Momentan nu ai acces la niciun beneficiu.',
    description: 'Pachetul standard de beneficii oferit de compania ta',
  }
}
