<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container :aria-hidden="loading ? 'true' : null">
      <div class="row container-gray py-3">
        <div class="col-12 text-bold">
          <span class="mr-1">{{ $t('flexben.beneficiary_pages.benefits.top_up_info') }}</span>
          <span id="top_up_dejun_information"
                class="d-inline-block flexben-tooltip-icon mr-2 text-bold">i</span>
          <b-tooltip :target="'top_up_dejun_information'" variant="flexben" placement="topleft">
            <div v-html="$t('flexben.beneficiary_pages.benefits.tooltips.up_dejun.top_up_info',{
                    benefit: ceBenefit.benefit.name,
                    value: ceBenefit.benefit.data.values.daily_ticket_min_value.value,
                    date: getLastTopupDate()
                  })" class="text-left"></div>
          </b-tooltip>
        </div>
        <div class="col-12 text-danger text-center text-bold" v-if="lastDayToOrderExceeded">
          {{ $t('flexben.formErrorReasons.exceeded_last_topup_date_client') }}
        </div>
        <div class="col-12 text-center text-bold text-danger mt-2"
             v-if="(!lastDayToOrderExceeded && ceBenefit.benefit.last_time_sheet.consumed_days !== 0 && !ceBenefit.benefit.company_config.configuration.multiple_upload_timesheet.active) || (!lastDayToOrderExceeded && ceBenefit.benefit.last_time_sheet.consumed_days >= ceBenefit.benefit.last_time_sheet.working_days && ceBenefit.benefit.company_config.configuration.multiple_upload_timesheet.active)"
             v-html="$t('flexben.beneficiary_pages.benefits.already_used',{benefit: ceBenefit.benefit.name})">
        </div>
        <div class="col-12 text-center text-bold text-danger mt-2"
             v-if="!lastDayToOrderExceeded && ceBenefit.benefit.last_time_sheet.working_days === 0">
          {{ $t('flexben.beneficiary_pages.benefits.no_time_sheet') }}
        </div>

        <div class="col-12 col-md-3 mt-2 mt-md-2" v-if="(last_time_sheet.working_days > 0)">
          <strong>{{ last_time_sheet.working_days }}</strong>
          {{ $t('flexben.beneficiary_pages.benefits.days_worked') }}
          {{ $t('flexben.general.months.'+last_time_sheet.month) }} {{ last_time_sheet.year }}
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-2" v-if="(last_time_sheet.working_days > 0)">
          <strong>{{ last_time_sheet.consumed_days }}</strong>
          {{ $t('flexben.beneficiary_pages.benefits.consumed') }}
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-2" v-if="(last_time_sheet.working_days > 0)">
          {{ $t('flexben.beneficiary_pages.benefits.last_day_to_place') }}
          <strong> {{ getLastTopupDate() }}</strong> {{ $t('flexben.general.hour') }} <strong>{{ getLastTopupHour()
          }}</strong>
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-2"
             :class="{'offset-md-8':(!lastDayToOrderExceeded && last_time_sheet.working_days === 0)}">
          {{ $t('flexben.beneficiary_pages.benefits.ticket_value') }}
          <strong>{{ individualTicketValue }} Lei</strong>
        </div>
      </div>
      <div class="row container-gray mt-3 py-3">
        <div class="col-12">
          <div class="text-bold float-left">{{ top_up_set_ticket_nr.enable ?
            $t('flexben.beneficiary_pages.benefits.top_up_count') : $t('flexben.beneficiary_pages.benefits.top_up_value')
            }}
          </div>
          <div class="float-right" v-if="values.taxes.allTaxes>0">
            <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
              $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
              : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
            </span>
            <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>

            <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
              <div class="text-left">
                {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
              </div>
            </b-tooltip>
            <span class="text-danger text-bold" v-html="`${values.taxes.allTaxes.toFixed(2)} lei`"></span>
          </div>
        </div>
        <div class="col-12">
          <div class="flexben-vue-slider-container">
            <template v-if="top_up_set_ticket_nr.enable">
              <vue-slider :key="'slider_values'"
                          :class="'px-2'"
                          :lazy="true"
                          v-bind="sliderOptions"
                          v-model="workedDays">
              </vue-slider>
            </template>

            <template v-else>
              <vue-slider v-if="sliderHasFixedValues"
                          :key="'slider_values'"
                          :class="'px-2'"
                          :lazy="true"
                          :data="sliderFixedValues"
                          v-bind="sliderOptions"
                          v-model="sliderValue">
              </vue-slider>
              <vue-slider v-else
                          :key="'slider_values'"
                          :class="'px-2'"
                          :lazy="true"
                          v-bind="sliderOptions"
                          v-model="sliderValue">
              </vue-slider>
            </template>
          </div>
        </div>
        <div class="col-12 col-md-8 col-xl-6"
             v-if="ceBenefit.benefit.last_time_sheet.consumed_days === 0 && ceBenefit.benefit.last_time_sheet.working_days!==0 && !sliderHasFixedValues">
          <button class="btn text-themed px-0" :class="{'d-flex':!showManualInput, 'd-none':showManualInput}"
                  @click="showManualInput=true">
            <font-awesome-icon icon="caret-square-down" class="mr-1 contact-ico"/>
            {{ $t('flexben.beneficiary_pages.benefits.btn.set_manual_ticket') }}
          </button>
          <div class="form-group m-0" v-show="showManualInput">
            <template v-if="top_up_set_ticket_nr.enable">
              <label for="manual_input" class="form-fields-required col-form-label">
                {{ $t('flexben.beneficiary_pages.benefits.top_up_count') }}
              </label>
              <select id="manual_input" class="form-control w-25"
                      :disabled="last_time_sheet.working_days === 0"
                      v-model="workedDays">
                <option v-for="(value, index) of sliderFixedValues" :key="index" :value="value">{{ value }}</option>
              </select>
            </template>

            <template v-if="!top_up_set_ticket_nr.enable">
              <label for="manual_input" class="form-fields-required col-form-label">
                {{ $t('flexben.beneficiary_pages.benefits.fields.manual_ticket_value',{
                min:ceBenefit.benefit.data.values.daily_ticket_min_value.value,
                max:ceBenefit.benefit.data.values.daily_ticket_max_value.value}) }}
              </label>
              <input
                type="number" class="form-control w-25" id="manual_input"
                step="1"
                onkeydown="if(event.key==='.'){event.preventDefault();}"
                :disabled="last_time_sheet.working_days === 0"
                v-model.number="sliderValue"
                :class="{'border-danger':manualInputInvalid}"
                :min="top_up_set_ticket_nr.enable ? 1 :ceBenefit.benefit.data.values.daily_ticket_min_value.value"
                :max="top_up_set_ticket_nr.enable ? last_time_sheet.working_days :ceBenefit.benefit.data.values.daily_ticket_max_value.value"
                @input="checkValue">
            </template>
          </div>
        </div>
        <total-value-pay v-if="companyOptions.gross_budget_taxation"
                         :totalPayValue="values.total"></total-value-pay>
      </div>

      <div class="row mt-3">
        <div class="col-12 p-0 align-items-center">
          <div class="d-md-inline-flex text-center text-md-left">
            <div class="d-flex flex-column flex-lg-row align-items-center align-items-lg-start align-content-center">
              <button class="btn btn-themed-green px-2 mr-2 px-lg-4" @click="orderNow()"
                      :disabled="(manualInputInvalid || lastDayToOrderExceeded ||
                        sliderValue === 0 ||
                        (ceBenefit.benefit.last_time_sheet.consumed_days !== 0 && !ceBenefit.benefit.company_config.configuration.multiple_upload_timesheet.active) ||
                        (ceBenefit.benefit.company_config.configuration.multiple_upload_timesheet.active && ceBenefit.benefit.order_not_completed) ||
                        (ceBenefit.benefit.last_time_sheet.consumed_days >= ceBenefit.benefit.last_time_sheet.working_days) ||
                        ceBenefit.benefit.last_time_sheet.working_days === 0 ||
                        budgets.available === 0 ||
                        !values.hasBudget ||
                        (companyOptions.gross_budget_taxation && totalPayValue>remainingBudget))">
                {{ $t('flexben.beneficiary_pages.order_now') }}
              </button>
              <a v-if="ceBenefit.benefit.product_link.length>0"
                 class="text-themed px-2 mr-lg-2 mt-2 px-lg-4 text-uppercase text-15 "
                 :href="ceBenefit.benefit.product_link" target="_blank">
                {{ $t('flexben.beneficiary_pages.benefits.btn.where_to_use_vouchers') }}
              </a>
            </div>
          </div>
          <div class="ml-auto d-flex text-right pr-2 mt-3 mt-md-2 d-block d-md-inline-flex float-md-right">
            <span class="text-bold mr-1 ml-auto">{{$t('flexben.beneficiary_pages.benefits.auto_toggle')}}</span>
            <span id="tooltip_auto_topup"
                  class="d-inline-block flexben-tooltip-icon mr-2">i</span>

            <b-tooltip :target="'tooltip_auto_topup'" variant="flexben" placement="topleft">
              <div class="text-left"
                   v-html="$t('flexben.beneficiary_pages.benefits.tooltips.auto_topup',{benefit:ceBenefit.benefit.name})"></div>
            </b-tooltip>

            <b-form-checkbox id="benefit_enable_auto" class="d-inline-block"
                             switch
                             @change="openAutoTopUpModal()"
                             v-model="toggleAutoTopup"></b-form-checkbox>
          </div>
        </div>
      </div>

      <auto-top-up-modal ref="auto_top_up_modal_component"
                         :enableAutoTopup="ceBenefit.enable_auto_topup"
                         :minValue="ceBenefit.benefit.data.values.daily_ticket_min_value.value"
                         :maxValue="ceBenefit.benefit.company_config.configuration.top_up_set_ticket_nr.enable ? ceBenefit.benefit.company_config.configuration.top_up_set_ticket_nr.ticket_value : ceBenefit.benefit.data.values.daily_ticket_max_value.value"
                         :previousValue="ceBenefit.ticket_value"
                         :previousTicketCount="ceBenefit.auto_number_of_tickets"
                         :benefitName="ceBenefit.benefit.name"
                         :sliderHasFixedValues="sliderHasFixedValues"
                         :rawSliderFixedValues="rawSliderFixedValues"
                         :topUpSetTicketNr="top_up_set_ticket_nr"
                         :tichetCountAutoTopUp = "tichetCountAutoTopUp"
                         @toggleAutoTopUp="toggleAutoTopUp"></auto-top-up-modal>
    </b-container>
  </b-overlay>
</template>
<script>
import moment from "moment";
import {BContainer, BFormCheckbox, BOverlay, BTooltip} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import 'vue-slider-component/dist-css/vue-slider-component.css'
import 'vue-slider-component/theme/default.css'
import AutoTopUpModal from "@/components/flexben/beneficiary_pages/modals/AutoTopUpModal";
import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
import flexbenUtils from "@/plugins/flexben_utils";

export default {
  name: "UpDejunForm",
  components: {
    'b-tooltip': BTooltip,
    'vue-slider': VueSlider,
    'b-form-checkbox': BFormCheckbox,
    'auto-top-up-modal': AutoTopUpModal,
    'total-value-pay': TotalValuePay,
    'b-overlay': BOverlay,
    'b-container': BContainer,
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      required: true,
      default() {
        return {
          taxes: {
            allTaxes: 0,
          },
          total: 0,
          hasBudget: true,
        };
      }
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    sliderGeneralOptions: {
      type: Object,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    globalVariables: {
      type: Object,
      required: true,
      default: function () {
        return {
          cas: null,
          cass: null,
          cam_tax: null,
          'impozit-venit': null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      manualInputInvalid: false,
      sliderValue: 0,
      workedDays: 0,
      consumedDays: 0,
      toggleAutoTopup: false,
      totalPayValue: 0,
      salaryDeductibility: 0,
      last_time_sheet: {},
      sliderOptions: {},
      sliderHasFixedValues: false,
      sliderHasFixedValuesFirst: false,
      sliderFixedValues: {},
      rawSliderFixedValues: [],
      rawSliderFixedValueIndex: 0,
      formatTooltip: (val) => {
        let diffWorkConsDays = this.ceBenefit.benefit.last_time_sheet.working_days - this.ceBenefit.benefit.last_time_sheet.consumed_days;
        let calcWorkedDays = diffWorkConsDays > 0 ? diffWorkConsDays : 0;
        if (this.sliderHasFixedValues && this.sliderHasFixedValuesFirst) {
          return this.rawSliderFixedValues[val] * calcWorkedDays + ' Lei'
        } else {
          this.sliderHasFixedValuesFirst = true;
          return val * calcWorkedDays + ' Lei'
        }
      },
      formatTooltipNrTickets: (val) => {
          return val * this.top_up_set_ticket_nr.ticket_value + ' Lei'
      },
      showManualInput: false,
      lastDayToOrderExceeded: false,
      individualTicketValue: 0,
      top_up_set_ticket_nr: {
        enable: false,
        ticket_value: null
      }
    };
  },
  watch: {
    sliderValue: {
      handler: function (val) {
        if (this._validate()) {
          return;
        }
        this._setPayload();
        this.$emit("getTaxes", this.payload.benefit_id, this.payload);
      }
    },
    workedDays: {
      handler: function (val) {
        if (this._validate()) {
          return;
        }
        this._setPayload();
        this.$emit("getTaxes", this.payload.benefit_id, this.payload);
      }
    },
    values: {
      handler() {
        if (!this.values.hasBudget) {
          this.$emit('makeToast', this.$t('rejectReason_balancenotvalid'));
        }
      }
    }
  },
  mounted() {
    this._setSliderOptions();
    this.lastDayToOrderExceeded = this.ceBenefit.benefit.last_day_hour_exceeded;
    this.toggleAutoTopup = this.ceBenefit.enable_auto_topup;
  },
  methods: {
    checkValue() {
      if (this.sliderValue === null || this.sliderValue === '')  {
        this.sliderValue = this.ceBenefit.benefit.data.values.daily_ticket_min_value.value;
      }
    },
    _getPayload() {
      let ticketCount =
        this.sliderValue /
        this.ceBenefit.benefit.ticket_value;
      return {
        value: this.sliderValue,
        benefit_id: this.ceBenefit.benefit.company_config.benefit_id,
        child_benefit_id: null,
        event_id: null,
        auto_top_up: false,
        ticket_count: ticketCount.toFixed(0),
        ticket_value: this.ceBenefit.benefit.ticket_value,
        extra: {}
      };
    },
    getLastTopupDate() {
      let month, day;
      if (this.ceBenefit.benefit.last_time_sheet.last_top_up_date === null) {
        day = this.ceBenefit.benefit.company_config.configuration.last_top_up_day;
        month = this.$t('flexben.beneficiary_pages.benefits.tooltips.up_dejun.every_month');
      } else {
        let momentMonth = moment(this.ceBenefit.benefit.last_time_sheet.last_top_up_date).locale(this.locale).format('MMMM');
        day = moment(this.ceBenefit.benefit.last_time_sheet.last_top_up_date).format('DD');
        month = momentMonth.trim().charAt(0).toUpperCase() + momentMonth.trim().slice(1);
      }
      return day + ' ' + month;
    },
    getLastTopupHour() {
      if (this.ceBenefit.benefit.company_config.configuration.last_top_up_hour < 10) {
        return '0' + this.ceBenefit.benefit.company_config.configuration.last_top_up_hour + ':00';
      }
      return this.ceBenefit.benefit.company_config.configuration.last_top_up_hour + ':00';
    },
    openAutoTopUpModal() {
      this.$refs['auto_top_up_modal_component'].openModal();
    },
    orderNow() {
      if (this._validate()) {
        return;
      }
      this._setPayload();
      this.$emit('orderNow');
    },
    _validate() {
      if (this.lastDayToOrderExceeded) {
        this.$emit('setErrorInAlertModal', [this.$t('flexben.beneficiary_pages.benefits.modal.auto_topup.last_day_hour_exceeded', {
          date_hour: this.getLastTopupDate() + ' ' + this.getLastTopupHour()
        })]);
        return true;
      } else if (
        !this.ceBenefit.benefit.company_config.configuration.top_up_set_ticket_nr.enable &&
        !this.ceBenefit.benefit.company_config.configuration.top_up_fixed_values.enable &&
        (
          this.ceBenefit.benefit.data.values.daily_ticket_min_value.value > this.sliderValue ||
          this.sliderValue > this.ceBenefit.benefit.data.values.daily_ticket_max_value.value
        )
      ) {
        this.$emit('setErrorInAlertModal', [this.$t('flexben.beneficiary_pages.benefits.modal.auto_topup.invalid_value', {
          min: this.sliderOptions.min,
          max: this.sliderOptions.max
        })])
        return true;
      }
      return false;
    },
    _setPayload() {
      let value;
      if (this.sliderHasFixedValues) {
        if (this.rawSliderFixedValues[this.sliderValue] !== undefined) {
          value = parseFloat(this.rawSliderFixedValues[this.sliderValue]);
        } else {
          value = this.rawSliderFixedValues[0];
        }
      } else {
        value = this.sliderValue;
      }
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.ticket_count = (this.consumedDays > 0 && !this.ceBenefit.benefit.company_config.configuration.top_up_set_ticket_nr.enable ) ? (this.workedDays - this.consumedDays) : this.workedDays;
      this.payload.value = (this.consumedDays > 0 && !this.top_up_set_ticket_nr.enable) ? (this.workedDays - this.consumedDays) * value : (this.workedDays * value);
      this.payload.extra = {daily_ticket_value: value};
    },
    _setSliderOptions() {
      let sliderGeneralOptions = {...this.sliderGeneralOptions};
      this.last_time_sheet = this.ceBenefit.benefit.last_time_sheet;
      this.top_up_set_ticket_nr = this.ceBenefit.benefit.company_config.configuration.top_up_set_ticket_nr;
      this.workedDays = this.ceBenefit.benefit.last_time_sheet.working_days;
      this.consumedDays = this.ceBenefit.benefit.last_time_sheet.consumed_days;

      if (this.top_up_set_ticket_nr.enable) {
        sliderGeneralOptions = this._getSliderValuesTicketCountOptions(sliderGeneralOptions);
      } else {
        sliderGeneralOptions = this._getSliderValuesTicketValueOptions(sliderGeneralOptions);
      }
      if (this.budgets.available === 0 || this.ceBenefit.benefit.last_time_sheet.working_days === 0 ||
        (this.ceBenefit.benefit.last_time_sheet.consumed_days !== 0 && !this.ceBenefit.benefit.company_config.configuration.multiple_upload_timesheet.active) ||
        (this.ceBenefit.benefit.company_config.configuration.multiple_upload_timesheet.active && this.ceBenefit.benefit.order_not_completed) ||
        (this.ceBenefit.benefit.last_time_sheet.consumed_days === this.ceBenefit.benefit.last_time_sheet.working_days && this.ceBenefit.benefit.company_config.configuration.multiple_upload_timesheet.active)
      )
      {
        sliderGeneralOptions.disabled = true;
      }

      this.sliderOptions = sliderGeneralOptions;

    },
    _getSliderValuesTicketValueOptions(sliderGeneralOptions) {
      this.sliderValue = this.ceBenefit.benefit.data.values.daily_ticket_min_value.value;
      let minVal = this.ceBenefit.benefit.data.values.daily_ticket_min_value.value;
      let maxVal = this.ceBenefit.benefit.data.values.daily_ticket_max_value.value;
      let calcWoredDays = (this.workedDays - this.consumedDays) > 0 ? (this.workedDays - this.consumedDays) : 0 ;
      let marks = {};
      if (this.ceBenefit.benefit.company_config.configuration.top_up_fixed_values.enable) {
        this.rawSliderFixedValues = this.ceBenefit.benefit.company_config.configuration.top_up_fixed_values.values;
        this.sliderValue = this.rawSliderFixedValues[0];
        this.sliderHasFixedValues = true;
        let sliderValues = [];
        for (let index in this.rawSliderFixedValues) {
          let roundValue = (this.rawSliderFixedValues[index] * calcWoredDays).toFixed(2);
          marks[index] = `${roundValue} Lei`;
          sliderValues.push(index);
        }
        if (this.rawSliderFixedValues.length === 1 || calcWoredDays === 0 ) {
          sliderGeneralOptions.disabled = true;
        }
        this.sliderFixedValues = sliderValues;
      } else {

        marks[minVal] = {
          label: (minVal * calcWoredDays) + ' Lei'
        };
        if (maxVal % this.ceBenefit.benefit.ticket_value > 0) {
          maxVal = maxVal - (maxVal % this.ceBenefit.benefit.ticket_value);
        }
        marks[maxVal] = {
          label: (maxVal * calcWoredDays) + ' Lei'
        };

        sliderGeneralOptions.min = minVal;
        sliderGeneralOptions.max = maxVal;
      }
      sliderGeneralOptions.marks = marks;
      sliderGeneralOptions.value = this.ceBenefit.benefit.data.values.daily_ticket_min_value.value;
      sliderGeneralOptions.tooltipFormatter = this.formatTooltip;

      return sliderGeneralOptions;
    },
    _getSliderValuesTicketCountOptions(sliderGeneralOptions) {
      let minVal = 1;
      let maxVal = this.ceBenefit.benefit.company_config.configuration.multiple_upload_timesheet.active ? this.last_time_sheet.working_days - this.last_time_sheet.consumed_days : this.last_time_sheet.working_days;

      this.sliderValue = this.top_up_set_ticket_nr.ticket_value * minVal;
      this.individualTicketValue = this.top_up_set_ticket_nr.ticket_value;
      this.workedDays = minVal;
      sliderGeneralOptions.value = minVal;
      sliderGeneralOptions.min = minVal;
      sliderGeneralOptions.max = maxVal;
      let marks = {};
      let sliderValues = [];
      for (let value of this.generateRange(1, maxVal)) {
        marks[value] = value;
        sliderValues.push(value);
      }

      this.sliderFixedValues = sliderValues;
      sliderGeneralOptions.marks = marks;
      sliderGeneralOptions.tooltipFormatter = this.formatTooltipNrTickets;

      return sliderGeneralOptions;
    },
    _setSalaryDeductibilityValue() {
      let val = this.sliderValue;
      if (this.sliderHasFixedValues) {
        if (this.sliderHasFixedValuesFirst) {
          val = this.rawSliderFixedValues[val];
        }
      }
      if (val !== 0) {
        let tax, withoutTax;
        let withoutTaxPercent = (this.ceBenefit.benefit.global_variables.cas + this.ceBenefit.benefit.global_variables.cass) / 100;
        let taxPercent = this.ceBenefit.benefit.global_variables['impozit-venit'] / 100;

        if (this.companyOptions.legislated_gross_calculation) {
          let gross = flexbenUtils.calculateGross(val, this.globalVariables);
          withoutTax = val * withoutTaxPercent;
          tax = (gross - (gross * (this.ceBenefit.benefit.global_variables.cass / 100))) * taxPercent;
        } else {
          withoutTax = val * withoutTaxPercent;
          tax = (val - withoutTax) * taxPercent;
        }

        let calcWorkedDay = this.consumedDays > 0 ? (this.workedDays - this.consumedDays) : this.workedDays;
        this.salaryDeductibility = ((withoutTax + tax) * parseInt(calcWorkedDay)).toFixed(2);
        this._calculateTotalByCompanyOptions(val * parseInt(calcWorkedDay));
      } else {
        this._calculateTotalByCompanyOptions(0);
        this.salaryDeductibility = 0;
      }
    },
    toggleAutoTopUp(confirm, ticketValue, ticketCount) {
      if (confirm) {
        //reset to original
        this.toggleAutoTopup = !this.ceBenefit.enable_auto_topup;
        this.$emit('toggleAutoTopUp', ticketValue, ticketCount);
      } else {
        //reset to original
        this.toggleAutoTopup = this.ceBenefit.enable_auto_topup;
      }
    },
    _calculateTotalByCompanyOptions(sliderValue) {
      let value = parseFloat(sliderValue);

      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      this.totalPayValue = value;
    }
  },
  computed: {
    tichetCountAutoTopUp() {
      return this.ceBenefit && this.ceBenefit.autoTopup && this.ceBenefit.autoTopup.auto_number_of_tickets != null
        ? this.ceBenefit.autoTopup.auto_number_of_tickets
        : 0;
    }
  }
}
</script>

