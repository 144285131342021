export default [
    {
        fileName: "beneficiary/roxana-blenche-up-online-banner.png",
        link:
            "https://www.youtube.com/watch?v=Yeo8EFuJ0TM",
        category: "Banner",
        action: "Click",
        label: "utilizator_campanie_blanche",
    },
    {
        fileName: "client/up-dejun-up-online-clienti.png",
        link: "https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fupromania.ro%2Fcompanii%2Fcard-de-masa%2F&data=05%7C01%7Cvpopa%40upromania.ro%7C8425de7d93d24c630b2408dac97f6eee%7C475fd1d1212f472f9c02635526f47568%7C0%7C0%7C638043846413138670%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=FAJbDBAoNBuAJKv%2BCUkfxNchxv0oS6opGlG%2FjsKcbJY%3D&reserved=0",
        category: "Banner",
        action: "Click",
        label: "utilizator_card_dejun",
    },
    {
        fileName: "client/up-onlline-clienti.png",
        link:
            "https://upromania.ro/companii/card-cultural/",
        category: "Banner",
        action: "Click",
        label: "utilizator_card_cultura",
    },
];
