<template>
  <b-modal :ref="'auto_top_up_modal'"
           :hide-header-close="true"
           headerClass="border-0 text-center pb-0"
           bodyClass="modal-body no-border background-white text-center "
           footerClass="modal-footer border-top-0"
           :no-close-on-backdrop="true"
           centered>
    <template v-slot:modal-header>
      <h2 class="text-center w-100">
        {{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.title') }}
        {{benefitName}}</h2>
    </template>
    <template v-slot:default>
      <p class="text-center text-danger" v-if="invalidTicketValue">
        {{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.invalid_value',{ min:minValue,
        max:maxValue }) }}
      </p>
      <p class="text-center"
         v-if="!enableAutoTopup"
         v-html="$t('flexben.beneficiary_pages.benefits.modal.auto_topup.text_1',
            { action:(enableAutoTopup ? $t('flexben.general.disable') : $t('flexben.general.enable')), benefit : benefitName })"></p>
      <p class="text-center"
         v-if="!enableAutoTopup && !topUpSetTicketNr.enable">{{
        $t('flexben.beneficiary_pages.benefits.modal.auto_topup.text_2')
        }}</p>
      <p class="text-center"
         v-if="!enableAutoTopup && topUpSetTicketNr.enable">{{
        $t('flexben.beneficiary_pages.benefits.modal.auto_topup.text_2_count', {value:topUpSetTicketNr.ticket_value})
        }}</p>
      <p class="text-center"
         v-if="!enableAutoTopup">{{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.text_3')
        }}</p>
      <p v-if="topUpSetTicketNr.enable"
         class="form-group text-center" :class="{'my-1 my-md-2':!enableAutoTopup }">
        <label for="modal_daily_ticket_count" class="form-fields-required w-75 mx-auto">
          {{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.label_count') }}</label>
        <select id="modal_daily_ticket_count"
                v-model="selectedTicketCount"
                :disabled="enableAutoTopup"
                class="form-control w-25 mx-auto">
          <option v-for="number of generateRange(1,25)" :value="number" :key="number">{{ number }}</option>
        </select>
      </p>

      <p v-if="!topUpSetTicketNr.enable"
         class="form-group text-center" :class="{'my-1 my-md-2':!enableAutoTopup }">
        <label for="modal_daily_ticket_value" class="form-fields-required w-75 mx-auto">
          {{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.label') }}</label>

        <select v-if="sliderHasFixedValues && !enableAutoTopup"
                id="modal_daily_ticket_value" class="form-control w-50 mx-auto"
                v-model="ticketValue">
          <option disabled>-</option>
          <option v-for="(value, index) of rawSliderFixedValues" :key="index" :value="value">{{ value }} Lei
          </option>
        </select>


        <input v-if="enableAutoTopup || !sliderHasFixedValues" class="form-control w-25 mx-auto"
               type="number"
               id="modal_daily_ticket_value"
               :min="minValue"
               :max="maxValue"
               pattern="[0-9]{10}"
               :disabled="enableAutoTopup"
               v-model.number="ticketValue"/>
      </p>
    </template>
    <template v-slot:modal-footer>
      <div class="w-100 text-center">
        <button class="btn btn-themed" @click="confirmAndClose()">
          {{ (enableAutoTopup) ?
          $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_disable') :
          $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable') }}
        </button>
      </div>
      <div class="w-100 text-center mt-2">
        <button class="btn" @click="closeModal()">{{ $t('flexben.general.btn.cancel') }}</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "AutoTopUpModal",
  components: {
    'b-modal': BModal,
  },
  props: {
    enableAutoTopup: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: true
    },
    minValue: {
      type: Number,
      default: 0,
      required: true
    },
    maxValue: {
      type: Number,
      default: 0,
      required: true
    },
    benefitName: {
      type: String,
      default: '',
      required: true
    },
    previousValue: {
      type: Number,
      default: 0,
      required: true
    },
    previousTicketCount: {
      type: Number,
      default: 0,
    },
    sliderHasFixedValues: {
      type: Boolean,
      default: false,
      required: true
    },
    rawSliderFixedValues: {
      type: [Array, Object],
      default: function () {
        return [];
      },
      required: true
    },
    topUpSetTicketNr: {
      type: Object,
      default: function () {
        return {
          enable: false,
          ticket_value: null
        };
      },
      required: true
    },
    tichetCountAutoTopUp: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      ticketValue: 0,
      ticketCount: 1,
      invalidTicketValue: false,
      localTicketCountAutoTopUp: this.tichetCountAutoTopUp
    };
  },
  methods: {
    openModal() {
      if (this.previousValue > 0) {
        this.ticketValue = this.previousValue;
      } else {
        if (this.sliderHasFixedValues) {
          this.ticketValue = parseFloat(this.rawSliderFixedValues[(this.rawSliderFixedValues.length - 1)]);
        } else {
          this.ticketValue = parseFloat(this.maxValue);
        }
      }

      this.ticketCount = this.previousTicketCount ? this.previousTicketCount : 0;
      this.$refs['auto_top_up_modal'].show();
    },
    closeModal() {
      this.$emit('toggleAutoTopUp', false)
      this.$refs['auto_top_up_modal'].hide();
    },
    confirmAndClose() {
      if (!this.topUpSetTicketNr.enable &&
        !this.enableAutoTopup && (
          (this.sliderHasFixedValues && !this.rawSliderFixedValues.includes(this.ticketValue)) ||
          (this.ticketValue < this.minValue || this.ticketValue > this.maxValue)
        )) {
        this.invalidTicketValue = true;
      } else {
        this.invalidTicketValue = false;
        this.$emit('toggleAutoTopUp', true, this.ticketValue, this.ticketCount);
        this.localTicketCountAutoTopUp = this.ticketCount;
        this.$refs['auto_top_up_modal'].hide();
      }
    },
  },
  computed: {
    selectedTicketCount: {
      get() {
        return this.localTicketCountAutoTopUp !== 0 ? this.localTicketCountAutoTopUp : this.ticketCount;
      },
      set(value) {
          this.ticketCount = value;
      }
    }
  }
}
</script>
