export default {
  legislated_type: 'Tratament legal',
  fiscal_treatment: "Tratament fiscal",
  legislated_types: {
    0: 'Legiferat',
    1: 'Nelegiferat',
  },
  btn: {
    export: 'Exporta beneficii',
    clear_category: 'Inapoi la categorii',
    back_button: 'Inapoi',
  },
  title: "Beneficii",
  description: "Configureaza beneficiile disponibile",
  valid_from_future: "Valabil de la",
  description_placeholder: 'Text descriere beneficiu. Maxim 3000 de caractere',
  reason_placeholder: 'Text descriere beneficiu. Maxim 3000 de caractere',
  future_values: "Valori viitoare",
  current_values: "Valori curente",
  save_draft: "Salveaza ca ciorna",
  set_inactive: "Inactiveaza",
  publish: "Publica",
  filter_title: "Filtreaza beneficii",
  add: "Adauga beneficiu",
  valid_from: "Intrare in vigoare",
  no_items_found: "Nu s-au gasit beneficii",
  id: 'ID CRM',
  rationale: 'Motivare',
  details: 'Detalii',
  available_categories: 'Categorii disponibile',
  change_status_to_invited: "Modifica in invitat",
  change_status_to_reActivate: "Reactivare User",
  available_categories_clients: 'Categorii specifice clienti disponibile',
  available_locations: 'Locatii disponibile',
  available_client_systems: 'Sisteme disponibile',
  daily_minimum_order_value: 'Valoare minima zilnica a comenzii',
  daily_maximum_order_value: 'Valoare maxima zilnica a comenzii',
  up_gift_law_tab_table_gender_restrictions: 'Disponibilitate gen',
  up_gift_law_tab_table_for_birthdays: 'Pentru zile de nastere',
  up_gift_law_tab_table_for_limit_child: "Limita parametrizabila per minor",
  maximum_value_per_order: "Valoare maxima pe comanda",
  maximum_value_per_event: "Valoare maxima pe eveniment",
  new_beneficiary: "Adauga beneficiar",
  delete: "Sterge",
  suspended: "Suspenda",
  reactivate: "Activeaza",
  resend_invitation: "Retrimite invitatie",
  beneficiary_created_successfully: "Beneficiarul a fost adaugat cu succes",
  beneficiary_updated_successfully: "Beneficiarul a fost modificat cu succes",
  status_change_successfully: "Statusul a fost schimbat cu succes",
  add_beneficiary: "Adauga beneficiar",
  status_changed_successfully: "Starea a fost shimbata cu succes.",
  action_has_been_executed_successfully: "Actiunea a fost executata cu succes.",
  crm_codes: {
    up_gift_legislated: 'Evenimente legiferate',
    up_gift_specific_events: 'Evenimente specifice',
    up_gift_s_t_i_m: 'STIM',
  },
  image_changed: "Imaginea schimbata nu a fost salvata",
  upload_image: "Imagine (500x500 px). Laturi egale",
  fields: {
    maximum_legal_value_per_month: "Valoare maxima legala pe luna",
    maximum_value_per_order_month: "Valoare maxima comanda pe luna",
    maximum_legal_value_per_year: "Valoare maxima legala pe an",
    minimum_value_per_order: "Valoare minima pe comanda",
    new_minimum_value_per_order: "Noua valoare minima pe comanda",
    new_maximum_legal_value_per_year: "Noua valoare maxima legala pe an",
    new_daily_ticket_min_value: "Noua valoare minima zilnica a tichetului",
    daily_ticket_min_value: "Valoare minima zilnica a tichetului",
    new_daily_ticket_max_value: "Noua valoare maxima zilnica a tichetului",
    new_maximum_legal_value_per_event: "Noua valoare maxima legala pe eveniment",
    valid_form_future: "Valabil de la",
    daily_ticket_max_value: "Valoare maxima zilnica a tichetului",
    maximum_legal_value_per_event: "Valoare maxima legala pe eveniment",
    new_maximum_legal_value_per_month: "Noua valoare maxima legala pe luna",
    new_maximum_value_per_order_month: "Noua valoare maxima comanda pe luna",
    new_maximum_value_per_event: "Noua valoare maxima pe eveniment",
    valid_form: "Valabil de la",
    latest_annual_for_placing_orders: "Ultima zi din an pentru plasarea comenzilor",
    daily_minimum_ticket_value: "Valoare minima zilnica a tichetului",
    daily_maximum_ticket_value: "Valoare maxima zilnica a tichetului",
    new_daily_minimum_ticket_value: "Noua valoare minima zilnica a tichetului",
    new_daily_maximum_ticket_value: "Noua valoare maxima zilnica a tichetului",
    sub_type: "Sub tip",
    child_sub_type:  'Alege furnizor',
    is_parent: "Este parinte",
    subscription_periods: 'Perioada abonament',
    product_link: 'Link produs',
    name: 'Nume',
    invoice_name: 'Nume factura',
    crm_code: 'ID CRM',
    description: 'Descriere',
    rationale: 'Motivatie',
    delivery_type: 'Tip livrare',
    extra: 'Campuri dinamice',
    voucher_billing_value: 'Valoare Lei',
    voucher_value_code: 'Cod voucher',
    voucher_value_file: 'Fisier voucher',
    status: 'Status',
    partner: 'Partener',
    county: 'Disponibilitate judet',
    min_order_value: 'Valoare minima comanda',
    new_min_order_value: 'Valoare minima comanda noua',
    info_contract_number: 'Beneficiarii vor avea campul "Nr. polita"(Nr. contract) generat in mod automat.',
    available_mobile: "Disponibil aplicatie mobila",
    budget_consumption_types: "Disponibil consum buget",
    budget_to_point_ratio: 'Raport buget la puncte',
    new_budget_to_point_ratio: 'Raport buget la puncte nou',
    platform_tax: 'Taxa procesare',
    new_platform_tax: 'Taxa procesare noua',
    vat: 'TVA',
  },
  modal: {
    confirm_save: 'Esti sigur ca vrei sa salvezi modificarile?',
    confirm_leave: 'Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?',
    confirm_delete: 'Esti sigur ca vrei sa stergi beneficiul?',
    save_draft: 'Esti sigur ca vrei sa salvezi beneficiul ca draft?',
    publish: 'Esti sigur ca vrei sa publici beneficiul?',
    confirm_status: 'Esti sigur ca vrei sa {action} beneficiul?',
    irreversible_action: 'Actiunea este ireversibila!',
    status_actions: {
      active: 'activeaza',
      inactive: 'dezactiveaza',
    }
  },
  edit: 'Editeaza',
  create: 'Beneficiu nou',
  dynamic_fields: 'Campuri dinamice',
  partner_data: 'Informatii partener',
  sub_types: {
    none: 'Nespecificat',
    voucher: 'Voucher',
    voucher_fuel: 'Voucher carburant',
    subscription: 'Abonament',
    subscription_world_class: 'Abonament WorldClass',
    ppp3: 'Pensie privata pilon 3',
    medical_insurance: 'Asigurare medicala',
    points_pago: 'Puncte pago',
    cash_out: 'Cash out',
    subscription_24_pay: 'Abonament 24Pay',
  },
  types: {
    transport: 'Transport'
  },
  thresholds: {
    gym_membership: 'Plafon abonament sportiv',
    tooltip: 'Plafon ce se aplica abonamentelor sportive (temporar si abonamentelor sub forma de voucher)'
  },
  tooltips: {
    category: 'Categoriile selectate pot face parte dintr-un singur tip'
  },
  subscription_periods: {
    w_1: 'O saptamana',
    w_2: 'Doua saptamani',
    m_1: '1 luna',
    m_3: '3 luni',
    m_6: '6 luni',
    m_12: '12 luni',
  },
  delivery_types: {
    digital_code: 'Digital - cod',
    digital_file: 'Digital - fisier',
    physical: 'Fizic',
    card: 'Alimentare card',
  },
  deductibility: {
    3: 'Nedeductibil',
    2: 'Deductibil partial',
    1: 'Deductibil integral',
    none: 'Nedeductibil',
    partial: 'Deductibil partial',
    complete: 'Deductibil integral',
  },
  no_partner: 'Partener nesetat',
  world_class_fields: {
    price: 'Pret',
    prices: 'Preturi',
    subscription_periods: 'Durata abonament',
    location: 'Locatie',
    facilities: 'Facilitati',
    time_schedule: 'Program',
    level: 'Nivel',
    city: 'Oras',
    club_name: 'Nume club',
    club_code: 'Cod club',
    is_local_price: 'Pret local',
    address: 'Adresa',
    phone_number: 'Numar telefon',
    has_gym: 'Sala fitnes',
    has_aerobic_studio: 'Studio aerobic',
    has_cycling_studio: 'Studio ciclism',
    has_pt: 'Antrenor personal',
    has_indoor_pool: 'Piscina interioara',
    has_outdoor_terrace: 'Terasa exterioara',
    has_outdoor_pool: 'Piscina exterioara',
    has_indoor_football: 'Footbal interior',
    has_dry_sauna: 'Sauna uscata',
    has_wet_sauna: 'Sauna umeda',
    has_jacuzzi: 'Jacuzzi',
    has_pilates_studio: 'Studio pilates',
    has_yoga_studio: 'Studio yoga',
    has_kids_studio: 'Studio copii',
    has_squash: 'Squash',
    last_day_to_order_for_next_month: 'Ultima zi de comanda pentru abonament in urmatoarea luna',
    subscription_start_day: 'Data incepere abonament',
    chose_club: 'Alege club',
    subscription_type: 'Tip abonament',
  },
  world_class_types: {
    network: 'Network',
    local: 'Local',
  },
  world_class_notices: {
    personal_info: 'Informatiile completate vor fi trasmise catre World Class cu scopul activarii abonamentului',
    local_price: 'Abonament cu acces doar in clubul selectat',
    network_price: 'Abonament cu acces in toate cluburile din aceeasi categorie si in cele din categoria inferioara clubului selectat',
    birth_date_tooltip: 'Data nasterii este solicitata de catre World Class pentru a verifica faptul ca sunteti major.'
  },
  world_class_import: {
    types: {
      label: 'Alege tipul de import',
      prices: 'Preturi network',
      locations: 'Locatii',
      prices_locale: 'Preturi locale'
    },
    upload: 'Incarca fisier',
    import: 'Import',
    no_file_uploaded: 'Niciun fisier incarcat'
  },
  transport_24_pay: {
    no_account_info: 'Va rugam sa introduceti numarul de telefon asociat contului dumneavoastra 24Pay sau sa creati un cont prin descarcarea aplicatiei 24Pay, pentru a avea acces la toate abonamentele disponibile.',
    start_date_info: 'Disponibilitiatea perioadei de start poate fi restrictionata in cazul in care exista alt abonament activ',
    expiration_date: 'Data expirare abonament',
    start_date: 'Data start abonament',
    phone_number: 'Numar telefon cont 24Pay',
    subscription_name: 'Nume abonament 24Pay',
    provider_name: 'Societatea de transport',
    order_id_24_pay: 'Nr. comanda in platforma 24Pay',
    period_name: 'Valabilitate abonament',
    active_subscription: 'Ai o subscriptie activa care expira pe data de: ',
    labels: {
      phone_number: 'Numar de telefon',
      provider: 'Societatea de transport',
      start_date: 'Data start abonament',
      expiration_date: 'Data expirare abonament',
      subscription: 'Abonament transport',
    }
  },
  points_pago_notices: {
    personal_info: 'Punctele vor fi alocate contului care corespunde adresei din campul "Email". <br> Daca nu ai cont Pago, poti folosi adresa de Email pentru a te inregistra si revendica punctele',
    processing_tax: 'Pretul include taxa de procesare a comenzii in valoare de {tax} Lei'
  },
  county_availability: {
    0: 'Toate judetele',
    1: 'Specific',
  },
  location_availability: {
    0: 'Oriunde',
    1: 'Platforma Online',
    2: 'Magazin Fizic'
  },
  budget_consumption_types: {
    general: 'Buget flexibil',
    telework: 'Buget telemunca',
    ppp3: 'Buget ppp3',
  },
  promoted_as_new: 'Beneficiu promovat in categoria de noutati',
  contribution_type: {
    personal: "Personala",
    employer: "Angajator",
    mixt: "Mixta",
  }
}
